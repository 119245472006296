import React from "react";
import { format } from "date-fns";
import {
  ListAllRoomsBookingRequest,
  useBookingRequest,
} from "../../../../api/grpc/booking/useBookingRequest";
import { useAllReservationContext } from "../../shared/context/AllReservationContext/AllReservationContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";

import { RoomsEventsResponse } from "../../../../api/grpc/booking/booking";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { ReservationCard } from "../shared/ReservationCard";
import { FavoriteWorkplace } from "../../../../api/grpc/account/account";
import { RoomErrorReservationCard } from "./RoomErrorReservationCard";

export type RoomSettings = {
  showMeetingOrganizer: boolean;
  showMeetingTitle: boolean;
};

interface Props {
  pickedRooms: FavoriteWorkplace[];
}

export const RoomsBookings = ({ pickedRooms }: Props) => {
  const { listAllRoomBookings } = useBookingRequest();
  const { startDate } = useAllReservationContext();
  const { timeZone } = useTimeZoneContext();

  const {
    loading,
    error,
    data,
    refetch: refetchRooms,
  } = useGrpcQuery<ListAllRoomsBookingRequest, RoomsEventsResponse>({
    method: listAllRoomBookings,
    variables: {
      startTime: format(startDate || new Date(), "yyyy-MM-dd"),
      endTime: format(startDate || new Date(), "yyyy-MM-dd"),
      roomsId: pickedRooms.map((workspace) => workspace.id),
      timeZone: timeZone,
    },
  });

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={
        <LoadingBox
          minHeight={230}
          style={{
            width: 300,
          }}
        />
      }
    >
      {error ? (
        <>
          {pickedRooms.map((room) => (
            <RoomErrorReservationCard id={room.id} />
          ))}
        </>
      ) : (
        <>
          {data?.data.map((item) => (
            <ReservationCard
              key={item.roomId}
              type="room"
              name={item.roomName}
              events={item.events}
              tags={item.roomTags.join(", ")}
              locationPath={item.roomLocationPath}
              workplaceID={item.roomId}
              refetch={refetchRooms}
              equipment={item.equipment}
              roomNumberOfSeats={item.roomNumberOfSeats}
              settings={{
                showMeetingOrganizer: item.showMeetingOrganizer,
                showMeetingTitle: item.showMeetingTitle,
              }}
              showAttendees={item.showAttendees}
            />
          ))}
        </>
      )}
    </HandleLoadingState>
  );
};
