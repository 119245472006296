// this function returns all children of a floor and handles the case
// when a floor has a Zone that cotains rooms/desks:

import { WorkplaceFields } from "../../../api/graphql/workplaces";
import { Attendee } from "../../../api/grpc/ggevent/ggevent";
import { BusyTimeSlots } from "../../../api/grpc/workplaces/ggevent/ggevent";
import { AmenitiesFilter } from "../../../api/grpc/workplaces/workplaces";
import { MarkerSettings } from "./useMarkerData";

interface Marker {
  id: string;
  type: any;
  name: string;
  latlng: [number, number];
  location: string;
  isBooked?: boolean;
  isBlocked?: boolean;
  tags: string[];
  isAssigned?: boolean;
  user?: string;
  username?: string;
  busySlots?: any;
  amenities?: AmenitiesFilter;
  numberOfSeats?: number;
}

export interface FloorChildrenType extends WorkplaceFields {
  zoneName?: string;
  busySlots?: BusyTimeSlots[];
  isAssigned?: boolean;
  organizer?: Attendee;
  allowDeleteMeetings?: boolean;
  amenities?: AmenitiesFilter;
  numberOfSeats?: number;
}

//the function below is used to get all the rooms, desks and wayfinders of a floor
//it also adds rooms, desks, and wayfinders from a zone, if the floor has any
export const useFloorData = (data: any) => {
  let roomsOfFloor: FloorChildrenType[] = [];
  let desksOfFloor: FloorChildrenType[] = [];
  let wayfindersfFloor: FloorChildrenType[] = [];

  if (data?.zones) {
    data.zones.map((zone: WorkplaceFields) => {
      zone.rooms?.map((room: FloorChildrenType) => {
        room = {
          ...room,
          zoneName: zone.name,
        };

        roomsOfFloor.push(room);
      });

      zone.desks?.map((desk: FloorChildrenType) => {
        desk = {
          ...desk,
          zoneName: zone.name,
        };
        desksOfFloor.push(desk);
      });

      zone.wayfinders?.map((wayfinder: FloorChildrenType) => {
        wayfinder = {
          ...wayfinder,
          zoneName: zone.name,
        };
        wayfindersfFloor.push(wayfinder);
      });
    });
  }

  data?.rooms?.map((room: WorkplaceFields) => {
    roomsOfFloor.push(room);
  });

  data?.desks?.map((desk: WorkplaceFields) => {
    desksOfFloor.push(desk);
  });

  data?.wayfinders?.map((wayfinder: WorkplaceFields) => {
    wayfindersfFloor.push(wayfinder);
  });

  return { roomsOfFloor, desksOfFloor, wayfindersfFloor };
};

// this function creates a new array that contains data of rooms, desks, and wayfinders
// then they are returned as markers, or shapes (rooms only)
export const useMarkerData = (
  rooms: FloorChildrenType[],
  desks: FloorChildrenType[],
  wayfinders: FloorChildrenType[],
  settings?: MarkerSettings
) => {
  let markerData: Array<Array<Marker>> = [
    rooms
      .filter((room) => {
        const roomHasMarker =
          room?.marker?.latitude !== undefined && room?.marker?.latitude !== 0;

        // if showRoomMarkers is true, return all rooms with markers
        // otherwise, return only rooms with markers that are not circle or polygonal
        if (settings?.showRoomMarkers) {
          return roomHasMarker;
        } else {
          return (
            roomHasMarker &&
            (room?.circleShape === null || room?.circleShape?.latitude === 0) &&
            room?.polygonal.length <= 0
          );
        }
      })
      .map((item) => {
        return {
          id: item.id,
          type: "Room",
          name: item.name,
          latlng: [item?.marker?.latitude, item?.marker?.longitude],
          isBooked: item.isBooked,
          isBlocked: item.isBlocked,
          location: item.zoneName,
          busySlots: item.busySlots,
          tags: item.tags,
          amenities: item.amenities,
          numberOfSeats: item.numberOfSeats,
        } as Marker;
      }),
    desks
      .filter(
        (desk) =>
          desk?.marker?.latitude !== undefined && desk?.marker?.latitude !== 0
      )
      .map((item) => {
        return {
          id: item.id,
          type: "Desk",
          name: item.name,
          latlng: [item?.marker?.latitude, item?.marker?.longitude],
          isBooked: item.isBooked,
          isBlocked: item.isBlocked,
          isAssigned: item.isAssigned,
          location: item.zoneName,
          user: !!item.account ? item.account.displayName : "",
          username: !!item.organizer ? item.organizer.displayName : "",
          avatar: !!item.organizer ? item.organizer.avatar?.url : "",
          assigneAllowToFind: item?.organizer?.allowToFind,
          assigneEmail: item?.organizer?.email,
          busySlots: item.busySlots,
          tags: item.tags,
        } as Marker;
      }),
    wayfinders
      .filter(
        (wayfinder) =>
          wayfinder?.marker?.latitude !== undefined &&
          wayfinder?.marker?.latitude !== 0
      )
      .map((item) => {
        return {
          id: item.id,
          type: "Wayfinder",
          name: item.name,
          latlng: [item?.marker?.latitude, item?.marker?.longitude],
          location: item.zoneName,
          user: !!item.account ? item.account.displayName : "",
          tags: item.tags,
        } as Marker;
      }),
  ];

  const cirleData = rooms
    .filter(
      (room) =>
        room?.circleShape?.latitude !== undefined &&
        room?.circleShape?.latitude !== 0
    )
    .map((item) => {
      return {
        id: item.id,
        type: "Room",
        name: item.name,
        latlng: [item?.circleShape?.latitude, item?.circleShape?.longitude],
        radius: item.circleShape?.radius,
        isBooked: item.isBooked,
        location: item.zoneName,
        busySlots: item.busySlots,
        tags: item.tags,
        amenities: item.amenities,
        numberOfSeats: item.numberOfSeats,
      };
    });

  const polygonalData = rooms
    .filter((room) => room.polygonal?.length > 0)
    .map((item) => {
      return {
        id: item.id,
        type: "Room",
        name: item.name,
        latlng: item.polygonal.map(({ latitude, longitude, type }) => {
          return {
            lng: longitude,
            lat: latitude,
            type: type,
          };
        }),
        isBooked: item.isBooked,
        location: item.zoneName,
        busySlots: item.busySlots,
        tags: item.tags,
        amenities: item.amenities,
        numberOfSeats: item.numberOfSeats,
      };
    });

  return { markerData, cirleData, polygonalData };
};
