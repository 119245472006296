import React, { useState } from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import { useReservationHelpers } from "../../Reservations/helpers/reservationUpdateHelpers";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";

import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import {
  Event,
  Event as RoomEvent,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

export const UpdateReservationModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
  allowToModifyEvent,
  disableEventActions,
  isAuthedUsersEvent,
  hideMeetingTitle,
  showAttendees,
}: ExtendedProps) => {
  const { timeZone } = useTimeZoneContext();
  // state
  const [selectedTime, setSelectedTime] = useState(
    utcToZonedTime(new Date(endTime), timeZone)
  );
  const [loading, setLoading] = useState(false);

  // contexts
  const { updateDeskReservation, updateRoomBooking } = useReservationHelpers();
  const { personalCalendar } = useCalendarContext();
  const { allDay } = useBookingsMapContext();

  let eventTime: DeskEvent | RoomEvent = {
    ...event,
    endTime: zonedTimeToUtc(selectedTime, timeZone).toISOString(),
    eventTimeZone: timeZone,
    allDay: allDay,
  };

  const definedUsername =
    !!personalCalendar?.length && isAuthedUsersEvent
      ? event?.organizer?.displayName || ""
      : userName;

  const handleUpdateReservation = async () => {
    if (event.allDay === true) {
      return;
    }

    setLoading(true);
    if (type === "room" && refetchRoom) {
      const owner = personalCalendar.length > 0 && isAuthedUsersEvent;
      const roomEvent = event as Event;

      const calendarId = roomEvent.calendarId;

      const calendarproviderId = owner
        ? personalCalendar[0].iD
        : roomEvent.calendarProviderId;

      await updateRoomBooking(
        calendarId,
        calendarproviderId,
        eventTime as RoomEvent,
        refetchRoom,
        roomEvent.mode2Organizer
      );
      return setLoading(false);
    }

    if (refetchDesk) {
      await updateDeskReservation(
        workplaceId,
        event.metaData.DESK_EVENT_ID,
        refetchDesk,
        eventTime as DeskEvent
      );
    }
    return setLoading(false);
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title={
        disableEventActions ? "Reservation details" : "Modify reservation?"
      }
      workspaceType={type}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="update"
      buttonClick={handleUpdateReservation}
      buttonTitle="Update"
      disableButton={loading}
      subject={
        // allowToModifyEvent || event?.organizer?.allowToFind
        event?.title
        // : undefined
      }
      loadSpinner={loading}
      loadTitle="Updating"
      handleModalClose={toggleModal}
      updateTime={selectedTime}
      startDate={utcToZonedTime(new Date(startTime), timeZone)}
      endDate={utcToZonedTime(new Date(endTime), timeZone)}
      handleUpdateTime={(e) => setSelectedTime(e)}
      classNames="modal-footer-row"
      allDay={event.allDay}
      disableEventActions={disableEventActions}
      hideMeetingTitle={hideMeetingTitle}
      attendees={event.attendees}
      showAttendees={showAttendees}
    />
  );
};
