import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { ModalFooter } from "reactstrap";
import { Button, ButtonColor } from "../../shared/Button/Button";

export const ModalFooterContent = ({
  toggleModal,
  disabled,
  loadSpinner,
  handleSave,
  className,
  title,
  buttonClassName,
  buttonColor,
  loadTitle,
}: PropsWithChildren<{
  toggleModal: () => void;
  handleSave: () => void;
  title?: string;
  disabled?: boolean;
  loadSpinner?: boolean;
  className?: string;
  buttonClassName?: string;
  buttonColor?: ButtonColor;
  loadTitle?: string;
}>) => {
  const classes = cs(className);

  return (
    <ModalFooter className={classes}>
      <Button
        size="small"
        color="outline-secondary"
        title="Cancel"
        onClick={toggleModal}
        className={cs(buttonClassName)}
      />

      <Button
        size="small"
        color={buttonColor || "primary"}
        title={title ?? "Save"}
        disabled={disabled}
        loadSpinner={loadSpinner}
        loadTitle={loadTitle}
        onClick={handleSave}
        className={cs(buttonClassName)}
      />
    </ModalFooter>
  );
};
