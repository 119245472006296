import React from "react";
import { Spinner } from "reactstrap";

import { Calendar } from "../../../api/grpc/calendarprovider/calendarprovider";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { CalendarTypeParam } from "../../../views/Manage/Calendars/CalendarSingle/CalendarSingle";
import { CenteredInformationBox } from "../../shared/CenteredInformationBox/CenteredInformationBox";
import { tooltips } from "../../../lib/utils/tooltips";
import { ListCalendars } from "./ListCalendars";
import { RpcError } from "@protobuf-ts/runtime-rpc";

import "./CalendarAccountsList.scss";

interface Props {
  loading: boolean;
  error: RpcError | null;
  data: Calendar[];
  calendarType: CalendarTypeParam;
}

export const CalendarAccountsList = (props: Props) => {
  const { loading, error, data, calendarType } = props;

  return (
    <div className="CalendarAccountsList">
      <HandleRequestState
        state={loading}
        placeholder={
          <div className="w-100 d-flex w-100 h-100 align-items-center justify-content-center">
            <Spinner
              color="primary"
              style={{ width: "5rem", height: "5rem" }}
            />
          </div>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <h4>
              {error?.message || "Something went wrong, please try again!"}
            </h4>
          }
        >
          {data.length === 0 ? (
            <CenteredInformationBox
              infoMessage={
                calendarType.toLowerCase() === "gsuite"
                  ? tooltips.calendars.emptyGSuite
                  : tooltips.calendars.emptyO365
              }
              message="Looks like you haven't added any calendar emails yet!"
            />
          ) : (
            <ListCalendars data={data} calendarType={calendarType} />
          )}
        </HandleRequestState>
      </HandleRequestState>
    </div>
  );
};
