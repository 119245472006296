import { toast } from "react-toastify";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { Event } from "../../../../api/grpc/booking/ggevent/ggevent";
import { UpdateMeetingRequest } from "../../../../api/grpc/booking/booking";
import { INFO_STRINGS, SUCCESS_STRINGS } from "../../../../lib/utils/constants";

export const useCheckOutRoom = () => {
  const { personalCalendar } = useCalendarContext();
  const { updateRoomBookings, updateMeetingByCoordinator } =
    useBookingRequest();
  const { timeZone } = useTimeZoneContext();
  const { pathname } = useRouter();

  const isAllReservation = pathname.includes("all-reservations");

  const checkOutRoom = async (event: Event, isAuthedUsersEvent?: boolean) => {
    let eventToBeChanged: Event = {
      ...event,
      endTime: new Date().toISOString(),
      eventTimeZone: timeZone,
    };

    try {
      const owner = personalCalendar.length > 0 && isAuthedUsersEvent;

      const calendarId = owner ? "" : event.calendarId;

      const calendarproviderId = owner
        ? personalCalendar[0].iD
        : event.calendarProviderId;

      const variables: UpdateMeetingRequest = {
        customerId: "",
        meeting: eventToBeChanged,
        calendarId,
        calendarproviderId,
        activateCheckOut: false,
      };

      let externalRoom;

      if (event.mode2Organizer || !isAllReservation) {
        const { response } = await updateRoomBookings(variables);
        externalRoom = response.isExternalBooking;
      } else {
        const { response } = await updateMeetingByCoordinator(variables);
        externalRoom = response.isExternalBooking;
      }

      if (externalRoom) {
        toast.info(INFO_STRINGS.externalRoom);
      } else {
        toast.success(SUCCESS_STRINGS.reservationUpdated);
      }
    } catch (error: any) {
      return toast.error(error?.message ?? "Couldn't check out room");
    }
  };

  return { checkOutRoom };
};
