import React, { PropsWithChildren } from "react";
import { useApolloClient } from "@apollo/client";

import { ROOM_FIELDS_FRAGMENT } from "../../../../api/graphql/rooms/rooms";

export const RoomErrorReservationCard = ({
  id,
}: PropsWithChildren<{
  id: string;
}>) => {
  const client = useApolloClient();

  const roomData = client.readFragment({
    id: `Room:${id}`,
    fragmentName: "RoomFields",
    fragment: ROOM_FIELDS_FRAGMENT,
  });

  //no need to display room error
  if (!roomData?.calendarId?.length || !roomData?.calendarProviderId?.length) {
    return null;
  }

  return (
    <div className="ReservationCard ErrorReservationCard default-box">
      <h6 className="overflow-text">{roomData?.name}</h6>

      <span>Failed to load workspace</span>
    </div>
  );
};
