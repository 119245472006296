import React from "react";

import { useAllReservationContext } from "../shared/context/AllReservationContext/AllReservationContext";

import { AddWorkspaceCalendar } from "./shared/AddWorkspaceCalendar";
import { DesksReservations } from "./DesksReservations/DesksReservations";
import { RoomsBookings } from "./RoomsBookings/RoomsBookings";
import { FavoriteWorkplaceEnum } from "../../../api/grpc/account/account";

export const AllReservationsContent = () => {
  const { pickedWorkspaces } = useAllReservationContext();

  const pickedDesks = pickedWorkspaces.filter(
    (workspace) =>
      workspace.type === FavoriteWorkplaceEnum.FavoriteWorkplace_DESK
  );

  const pickedRooms = pickedWorkspaces.filter(
    (workspace) =>
      workspace.type === FavoriteWorkplaceEnum.FavoriteWorkplace_ROOM
  );

  return (
    <div className="AllReservationsContent mb-4">
      {pickedDesks.length > 0 && (
        <DesksReservations pickedDesks={pickedDesks} />
      )}

      {pickedRooms.length > 0 && <RoomsBookings pickedRooms={pickedRooms} />}

      {pickedWorkspaces.length < 10 && <AddWorkspaceCalendar />}
    </div>
  );
};
