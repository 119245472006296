import React, { useState } from "react";
import { useReservationHelpers } from "../../Reservations/helpers/reservationUpdateHelpers";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { utcToZonedTime } from "date-fns-tz";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";

import {
  CheckInRequiredStatus,
  Event,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";

import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

export const DeleteModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
  disableEventActions,
  isAuthedUsersEvent,
  hideMeetingTitle,
  showAttendees,
}: ExtendedProps) => {
  const [loading, setLoading] = useState(false);
  const { timeZone } = useTimeZoneContext();
  const { personalCalendar } = useCalendarContext();
  const { user } = useAuthContext();

  const { deleteDeskReservation, deleteRoomReservation } =
    useReservationHelpers();

  const definedUsername =
    !!personalCalendar?.length && isAuthedUsersEvent
      ? event?.organizer?.displayName || ""
      : userName;

  const handleDeleteReservation = async () => {
    try {
      setLoading(true);
      if (type === "desk" && refetchDesk) {
        return await deleteDeskReservation({
          deskID: workplaceId,
          eventID: event.metaData.DESK_EVENT_ID,
          accountID: event?.organizer?.id || "",
          refetch: refetchDesk,
        });
      }

      if (refetchRoom) {
        const owner = personalCalendar.length > 0 && isAuthedUsersEvent;

        const roomEvent = event as Event;
        const calendarId = owner ? "" : roomEvent.calendarId;

        const calendarproviderId = owner
          ? personalCalendar[0].iD
          : roomEvent.calendarProviderId;

        return await deleteRoomReservation(
          calendarId,
          calendarproviderId,
          roomEvent.metaData,
          refetchRoom,
          roomEvent.checkInRequiredStatus ===
            CheckInRequiredStatus.CHECK_IN_REQUIRED,
          user?.email || "",
          roomEvent.mode2Organizer
        );
      }
    } catch (error: any) {
      //error is handled in the helper function
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Delete reservation?"
      icon="delete-reservation"
      workspaceType={type}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="delete"
      buttonClick={handleDeleteReservation}
      buttonTitle="Delete"
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
      subject={event?.title}
      allDay={event.allDay}
      hideMeetingTitle={hideMeetingTitle}
      disableEventActions={disableEventActions}
      attendees={event.attendees}
      showAttendees={showAttendees}
      disableButton={loading}
      loadSpinner={loading}
      loadTitle="Deleting"
    />
  );
};
