import React, { FC, useEffect, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import { trimText, RED, GREEN, OPACITY } from "../RNMapView";

interface Props {
  id: string;
  isBooked: boolean;
  name: string;
  polygonal: {
    latitude: number;
    longitude: number;
  }[];
  eventHandlers?: L.LeafletEventHandlerFnMap;
  showTooltips?: boolean;
  showRoomMarkers?: boolean;
}

const RNPolygonal: FC<Props> = ({
  id,
  isBooked,
  name,
  eventHandlers,
  polygonal,
  showRoomMarkers,
  showTooltips,
}) => {
  const [color, setColor] = useState<typeof RED | typeof GREEN>(() =>
    isBooked ? RED : GREEN
  );

  useEffect(() => {
    if (isBooked === undefined) {
      return;
    }

    setColor(isBooked ? RED : GREEN);
  }, [isBooked]);

  return (
    <div>
      <Polygon
        key={`${id}${isBooked ? RED : GREEN}`}
        positions={polygonal.map((p) => [p.latitude, p.longitude])}
        color={color}
        fillColor={color}
        fillOpacity={OPACITY}
        weight={2}
        eventHandlers={eventHandlers}
      >
        {showTooltips && !showRoomMarkers && (
          <Tooltip
            className={`room-permanent-tooltip ${
              isBooked ? "room-booked" : ""
            }`}
            direction="center"
            offset={[0, -10]}
            opacity={1}
            permanent
          >
            <div>
              <span>{trimText(name || "", 18)}</span>
            </div>
          </Tooltip>
        )}
      </Polygon>
    </div>
  );
};

export { RNPolygonal };
