import React, { FC, useEffect, useState } from "react";
import { Circle, Tooltip } from "react-leaflet";
import { trimText, RED, GREEN, OPACITY } from "../RNMapView";

interface Props {
  isBooked: boolean;
  name: string;
  circle: {
    id: string;
    latitude: number;
    longitude: number;
    radius: number;
  };
  showTooltips?: boolean;
  showRoomMarkers?: boolean;
}

const RNCircle: FC<Props> = ({
  isBooked,
  name,
  circle,
  showRoomMarkers,
  showTooltips,
}) => {
  const [color, setColor] = useState<typeof RED | typeof GREEN>(() =>
    isBooked ? RED : GREEN
  );

  useEffect(() => {
    if (isBooked === undefined) {
      return;
    }

    setColor(isBooked ? RED : GREEN);
  }, [isBooked]);

  return (
    <div>
      <Circle
        key={`${circle.id}${isBooked ? RED : GREEN}`}
        center={[circle.latitude, circle.longitude]}
        radius={circle.radius}
        color={color}
        fillColor={color}
        fillOpacity={OPACITY}
        weight={2}
      >
        {showTooltips && !showRoomMarkers && (
          <Tooltip
            className={`room-permanent-tooltip ${
              isBooked ? "room-booked" : ""
            }`}
            direction="center"
            offset={[0, -10]}
            opacity={1}
            permanent
          >
            <div>
              <span>{trimText(name || "", 18)}</span>
            </div>
          </Tooltip>
        )}
      </Circle>
    </div>
  );
};

export { RNCircle };
