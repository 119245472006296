import React, { useMemo } from "react";
import { Form, FormGroup } from "reactstrap";
import { utcToZonedTime } from "date-fns-tz";
import { addMinutes } from "date-fns";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { dateToHoursAndMinutes } from "../../../lib/dateInputConvert";

import { Icon, IconDefinition } from "../../../../shared/Icon/Icon";
import { LocationPath } from "../../../shared/LocationPath/LocationPath";
import { LocationItem } from "../../../../../api/grpc/workplaces/workplaces";
import { ModalRow } from "./ModalRow";
import { MainResources } from "../../../../../lib/types/main.types";
import { LineDivider } from "../../../../shared/LineDivider/LineDivider";
import { EndTimeInputs } from "../../../form/EndTimeInputs/EndTimeInputs";
import { ModalType } from "../../../shared/BookingsModal/BookingsModal";
import { Attendee } from "../../../../../api/grpc/booking/ggevent/ggevent";

interface Props {
  title: string;
  workspaceType: MainResources;
  workspaceName: string;
  day: string;
  startTime: string;
  endTime: string;
  userName: string;
  locationPath: LocationItem[];
  type: ModalType;
  workspaceIconType?: IconDefinition;
  subject?: string;
  updateTime?: Date;
  handleUpdateTime?: (date: Date) => void;
  allDay?: boolean;
  icon?: IconDefinition;
  disableEventActions?: boolean;
  startDate?: Date;
  endDate?: Date;
  everyDay?: boolean;
  attendees?: Attendee[];
  hideMeetingTitle?: boolean;
  showAttendees?: boolean;
}

export const BookingsModalBody = ({
  title,
  icon,
  workspaceIconType,
  workspaceType,
  workspaceName,
  day,
  startTime,
  endTime,
  userName,
  subject,
  locationPath,
  type,
  updateTime,
  handleUpdateTime,
  allDay,
  disableEventActions,
  startDate,
  endDate,
  everyDay,
  attendees,
  hideMeetingTitle,
  showAttendees,
}: Props) => {
  const { timeZoneFormatted, timeZone } = useTimeZoneContext();
  const utc = timeZoneFormatted.split(" ")[0];
  const definedUtc = utc.replace(":00", "");

  const handleDurationChange = (hour: number, minute: number) => {
    if (!endDate) {
      return;
    }
    const durationInMinutes = hour * 60 + minute;
    const newEndTime = addMinutes(endDate, durationInMinutes);

    if (handleUpdateTime) {
      handleUpdateTime(newEndTime);
    }
  };

  const defineSubject = () => {
    if (type === "warning") {
      return "You have an assigned desk";
    }

    if (workspaceType === "desk") {
      if (type === "update") {
        return "Details:";
      } else {
        return "Summary:";
      }
    }

    if (hideMeetingTitle) {
      return "Details:";
    }

    return subject || "(No subject)";
  };

  const showExtendTime =
    ["check-out", "update"].includes(type) && !allDay && !disableEventActions;

  const defineAttendees = useMemo(() => {
    if (workspaceType === "desk" || !attendees?.length || !showAttendees) {
      return;
    }

    return attendees
      .filter((attendee) => !attendee.mode1Organizer)
      .map((att) => att.displayName)
      .join(", ");
  }, [attendees, showAttendees, workspaceType]);

  return (
    <>
      <h5 className="text-uppercase mb-3 mt-4">{title}</h5>

      <Icon icon={icon} className="BookingsModal__mainIcon" />

      <LineDivider />

      {!disableEventActions && (
        <>
          {type === "update" && handleUpdateTime && updateTime && (
            <>
              <Form className="SearchWorkplaceForm w-90">
                <FormGroup className="SearchWorkplaceForm--text mb-0 flex-a-center">
                  <EndTimeInputs
                    startTime={startDate || new Date()}
                    endTime={updateTime}
                    disabled={allDay}
                    onChange={(date) => {
                      if (!date || allDay) {
                        return;
                      }
                      return handleUpdateTime(date);
                    }}
                    handleDurationChange={handleDurationChange}
                    isUpdateMode={true}
                    filterPassedTime={(time) => {
                      if (!timeZone) {
                        return false;
                      }
                      const currentDate = utcToZonedTime(new Date(), timeZone);
                      const selectedDate = new Date(time);

                      return currentDate.getTime() < selectedDate.getTime();
                    }}
                  />
                </FormGroup>
              </Form>
            </>
          )}
        </>
      )}

      <h5 className="overflow-text w-90 mb-2 mt-1 text-center text-pro">
        {defineSubject()}
      </h5>

      <div className="BookingsModal__body">
        <ModalRow
          icon={
            workspaceIconType || workspaceType === "desk"
              ? "desk_icon_18x18"
              : "room_icon_18x18"
          }
          utc={definedUtc}
          title={workspaceName}
        />

        <ModalRow
          icon="calendar-bookings"
          title={day}
          startTime={startTime}
          endTime={endTime}
          allDay={allDay}
          utc={definedUtc}
          everyDay={everyDay}
          strikeThroughEndTime={showExtendTime}
          showExtendTime={
            showExtendTime
              ? dateToHoursAndMinutes(
                  updateTime ? new Date(updateTime) : new Date(),
                  timeZone
                )
              : undefined
          }
        />

        <ModalRow icon="single-user-thin" title={userName} utc={definedUtc}>
          {!!defineAttendees?.length && (
            <div className="ModalRow flex-a-center flex-column py-2">
              <Icon icon="users-icon-rounded" />
              <span className="text-center text-secondary">
                {defineAttendees}
              </span>
            </div>
          )}
        </ModalRow>

        {locationPath.length > 0 && (
          <div className="ModalRow flex-a-center flex-column pt-2 pb-3">
            <Icon icon="location-rounded" />
            <LocationPath location={locationPath} />
          </div>
        )}
      </div>
    </>
  );
};
